import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["colorPicker", "selectedColorsContainer"]

  connect() {
    console.log("Connected to ThreadSequencesController");

    if (!this.colorPickerTarget) {
      console.error("Unable to find colorPicker.");
      return;
    }

    // Remove 'required' attribute and add novalidate to prevent form validation
    this.colorPickerTarget.removeAttribute("required");
    
    // Find the parent form and add novalidate
    const parentForm = this.element.closest('form');
    if (parentForm) {
      parentForm.setAttribute('novalidate', '');
    }

    // Add disabled and required=false explicitly
    this.colorPickerTarget.disabled = true;
    this.colorPickerTarget.required = false;

    if (!this.selectedColorsContainerTarget) {
      console.error("Unable to find selectedColorsContainer.");
      return;
    }

    if (this.hasColorPickerTarget) {
      this.colorPickerTarget.addEventListener("change", this.addColor.bind(this));
    }

    this.selectedColorsContainerTarget.addEventListener("click", (event) => {
      if (event.target.classList.contains("remove-field")) {
        event.target.parentElement.remove();
      }
    });
  }

  addColor(event) {
    const selectedIndex = event.target.selectedIndex;
    const selectedText = event.target.options[selectedIndex].text;
    const color = event.target.value;

    if (!color) return; // Prevent invalid selections

    this.selectedColorsContainerTarget.insertAdjacentHTML("beforeend", `
      <div>
        <span>${selectedText}</span>
        <button type="button" class="remove-field">X</button>
        <input type="hidden" name="decoration[embroidery_attributes][thread_codes][]" value="${color}">
      </div>
    `);

    // Reset dropdown to the placeholder option
    this.colorPickerTarget.selectedIndex = 0;
  }
}