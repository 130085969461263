import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["decoratorSelect", "methodSelect", "methodType"]

  connect() {
    console.log("DecorationMethodSelector controller connected!")

    // Check if a decorator is preselected and fetch methods
    if (this.decoratorSelectTarget.value) {
      console.log("Decorator is already selected. Fetching methods...");
      this.updateMethods();
    }
    
    // Initialize with saved method if we have both decorator and method type
    if (this.decoratorSelectTarget.value && this.methodTypeTarget.value) {
      this.initializeWithSavedMethod()
    }
  }

  async initializeWithSavedMethod() {
    await this.updateMethods()
    
    // After methods are loaded, find and select the saved method
    const currentMethodId = this.methodSelectTarget.dataset.currentMethodId
    if (currentMethodId) {
      const option = Array.from(this.methodSelectTarget.options)
        .find(opt => opt.value === currentMethodId)
      
      if (option) {
        option.selected = true
        this.methodChanged()
        
        // Trigger the showhide toggle
        const changeEvent = new Event('change')
        this.methodSelectTarget.dispatchEvent(changeEvent)
      }
    }
  }

  decoratorChanged() {
    console.log("Decorator changed!")
    this.updateMethods()
  }

  async updateMethods() {
    const decoratorId = this.decoratorSelectTarget.value
    if (!decoratorId) {
      this.clearMethods()
      return
    }

    console.log("Updating methods for decorator:", decoratorId)
  
    try {
      const response = await fetch(`/administration/selections/decoration_methods?decorator_id=${decoratorId}`)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const data = await response.json()
      console.log("Received data:", data)
  
      this.populateMethods(data.results)
    } catch (error) {
      console.error("Error fetching decoration methods:", error)
      this.clearMethods()
    }
  }

  clearMethods() {
    if (this.hasMethodSelectTarget) {
      this.methodSelectTarget.innerHTML = ""
      const placeholderOption = new Option("Select a method", "")
      this.methodSelectTarget.add(placeholderOption)
    }
  }

  populateMethods(methods) {
    this.clearMethods()
    
    const currentMethodId = this.methodSelectTarget.dataset.currentMethodId

    methods.forEach(({ id, text, predicate_method }) => {
      console.log(`Adding option: ${text} (${id})`)
      const option = new Option(text, id)
      option.dataset.predicateMethod = predicate_method
      this.methodSelectTarget.add(option)

      if (id.toString() === currentMethodId?.toString()) {
        option.selected = true
        // Trigger method changed to update UI state
        this.methodChanged()
        
        // Trigger the showhide toggle
        const changeEvent = new Event('change')
        this.methodSelectTarget.dispatchEvent(changeEvent)
      }
    })
  }
  
  methodChanged() {
    if (this.handlingChange) return
    this.handlingChange = true
  
    const selectedOption = this.methodSelectTarget.selectedOptions[0]
    if (!selectedOption) {
      this.handlingChange = false
      return
    }
  
    const methodType = selectedOption.dataset.predicateMethod
    if (methodType && this.hasMethodTypeTarget) {
      console.log("Setting method type to:", methodType)
      this.methodTypeTarget.value = methodType
  
      // Update the select's data attribute for showhide
      this.methodSelectTarget.setAttribute('data-showhide-input-value', methodType)
  
      const event = new CustomEvent("decoration-method-changed", {
        detail: { 
          methodType,
          methodId: selectedOption.value
        },
        bubbles: true
      })
      this.methodTypeTarget.dispatchEvent(event)
    }
  
    this.handlingChange = false
  }
  
}