import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "input", "output" ];
  static values = { 
    showIf: String, 
    attributeName: String 
  }

  connect() {
    console.log("showhide controller connected");
    this.toggle();
  }

  toggle() {
    if (!this.hasInputTarget || !this.hasOutputTarget) return;

    const selectedOption = this.inputTarget.selectedOptions?.[0];
    if (!selectedOption) {
      this.hideAndDisableOutput();
      return;
    }

    let valueToCheck;
    
    // Check for data attribute first
    if (this.hasAttributeNameValue) {
      const datasetKey = this.convertToCamelCase(this.attributeNameValue);
      valueToCheck = selectedOption.dataset[datasetKey];
    }

    // Fall back to regular value if no data attribute or it's empty
    if (!valueToCheck) {
      valueToCheck = selectedOption.value;
    }

    console.log("Checking value:", valueToCheck, "against:", this.showIfValue);

    if (valueToCheck === this.showIfValue) {
      this.showAndEnableOutput();
    } else {
      this.hideAndDisableOutput();
    }
  }

  hideAndDisableOutput() {
    this.outputTarget.hidden = true;
    this.disableFields();
  }

  showAndEnableOutput() {
    this.outputTarget.hidden = false;
    this.enableFields();
  }

  enableFields() {
    const fields = this.outputTarget.querySelectorAll("input, select, textarea");
    fields.forEach(field => {
      field.disabled = false;
      field.required = true; // Make fields required when visible
    });
  }

  disableFields() {
    const fields = this.outputTarget.querySelectorAll("input, select, textarea");
    fields.forEach(field => {
      field.disabled = true;
      field.required = false; // Remove required when hidden
    });
  }

  convertToCamelCase(attribute) {
    return attribute.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
  }
}